import React from 'react';
import { navigate } from 'gatsby'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {convertNodeToElement} from "react-html-parser"
import moment from "moment"
import {document} from "browser-monads"
import {config} from "./configs"

export function isNullUndefined(item) {
    try {
      return item === null || item === undefined;
    } catch (err) {
      return true;
    }
}

export function isString(item) {
  try {
    if (typeof item === 'string') {
      return true;
    }
  } catch (err) {
    return false;
  }
}

export function isArray(item) {
  try {
    if (Array.isArray(item)) {
      return true;
    }
  } catch (err) {
    return false;
  }
}

export function isObject(item) {
  try {
    return item !== null && item !== undefined && typeof item === 'object';
  } catch (err) {
    return false;
  }
}

export function isEmptyArray(array) {
  try {
    if (isNullUndefined(array)) {
      return true;
    } else {
      return !(array instanceof Array && array.length > 0);
    }
  } catch (err) {
    return true;
  }
}

export function isEmptyString(value) {
  try {
    if (typeof value !== 'string') {
      return true;
    }
    return value.trim() === '';
  } catch (err) {
    return true;
  }
}

export function getMomentDate(date){
  if(isNullUndefined(date)){
    return null
  }
  return moment.parseZone(date)
}

export function deriveHomePathFromSpace(spaceId){
  switch(spaceId){
    case process.env.GATSBY_INVESTOR_SPACE_ID:
      return "/Investor/home";
    case process.env.GATSBY_UK_RESIDENTS_HCP_SPACE_ID:
      return "/UkResidents/HCP/home";
    case process.env.GATSBY_UK_RESIDENTS_NON_HCP_SPACE_ID:
      return "/UkResidents/Non-HCP/home"
    case process.env.GATSBY_NON_UK_RESIDENTS_HCP_SPACE_ID:
      return "/NonUkResidents/HCP/home";
    case process.env.GATSBY_NON_UK_RESIDENTS_NON_HCP_SPACE_ID:
      return "/NonUkResidents/Non-HCP/home";
    default:
      return "/";
  }
}

export const sortByLatestYear = (a, b) => {
  if(parseInt(a) > parseInt(b)){
    return -1;
  }
  if(parseInt(a) < parseInt(b)){
    return 1
  }
  return 0;
}

export function isEmailValid(email){
  const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
};

export function doesPasswordConformToAWSStandards(password){
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/.test(password)
}

export function validatePassword(password) {
  if (password.length < 8) {
      return {
        error: true,
        message: "Your password must be at least 8 characters"
      }
  }
  if (password.search(/[a-z]/i) < 0) {
      return {
        error: true,
        message: "Your password must contain at least one letter."
      }
  }
  if (password.search(/[0-9]/) < 0) {
      return {
        error: true,
        message: "Your password must contain at least one digit."
      }
  }

  if(!(/[-!$%@^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(password))){
      return {
        error: true,
        message: "Your password should contain at least one special character."
      }
  }
  
  return {
    error: false,
    message: null
  };
}

export const matchLink = (word) => {
  const regexExpression = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return regexExpression.test(word)
}

const TabularComponent = ({ table }) => {
  if(isNullUndefined(table)){
    return <React.Fragment />
  }

  let sliceHeaders = true;
  for(let header of table[0]){
    if(!isEmptyString(header) && !isNullUndefined(header)){
      sliceHeaders = false;
      break;
    }
  }

  return (
    <table className="t-cms table table-bordered vertical-align-middle">
      <tbody>
        {table.slice(sliceHeaders ? 1 : 0).map((row, index) => {
          return (
            <tr key={index}>
              {row.map((column, i) => {
                return (
                  <td key={i}>{column || ""}</td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  );
}

export const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const alt = node.data.target.fields.title['en-US']
      const url = node.data.target.fields.file['en-US'].url
      return <img key={`${url}-key`} alt={alt} src={url} />
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const contentTypeId = node.data.target.sys.contentType.sys.contentful_id;
      switch(contentTypeId){
        case "styledText":
          let {text, styling} = node.data.target.fields;
          if(text && styling){
            if(styling['en-US'] === "superscript"){
              return <sup>{text['en-US']}</sup>
            }else if(styling['en-US'] === "subscript"){
              return <sub>{text['en-US']}</sub>
            }
          }
          return <React.Fragment />
        default:
          return <React.Fragment />
      }
      // return <TabularComponent title={title} table={table} />
    }
  }
}

export const redesignLink = url => {
  if(url.startsWith("www")){
    return `http://${url}`
  }
  if(url==="/"){
    return config.defaultBaseUrl
  }
  return url;
}

export const isExternalButNotMailLink = (url) => {
  const contentfulAssetUrls = ["ctfassets.net"];

  let diurnalDomains = Object.keys(config).entries(([key, url]) => url)
  
  let domains = [...diurnalDomains, ...contentfulAssetUrls];

  let isDiurnalLink = false;

  for(let domain of domains){
    if( url.indexOf(domain) !== -1){
      isDiurnalLink = true;
      break;
    }
  }

  let isEmailLink = url.indexOf("mailto:") !== -1;
  let isInternalLink = url.startsWith("/");
  return (!isEmailLink && !isDiurnalLink && !isInternalLink);
}

export const isInternalPageLink = url => {
  const contentfulAssetUrls = ["ctfassets.net"];
  let diurnalDomains = Object.keys(config).entries(([key, url]) => url)

  let domains = [...diurnalDomains,  ...contentfulAssetUrls];

  let isEmailLink = url.indexOf("mailto:") !== -1;
  let isInternalLink = url.startsWith("/");

  return (!isEmailLink && isInternalLink);
}

export function addDisclaimersToCmsLinks(node, index, showDisclaimer) {
  if (node.type == "tag" && node.name == "a") {
    let {href: linkUrl} = node.attribs;

    const redirectionLogic = (e) => {
      e.preventDefault();
      
      linkUrl = redesignLink(linkUrl);

      if(!showDisclaimer){
        window.open(linkUrl);
        return;
      }

      if(isInternalPageLink(linkUrl)){
        navigate(linkUrl);
        return;
      }

      if(isExternalButNotMailLink(linkUrl)){
        showDisclaimer(linkUrl);
        return;
      }

      window.open(linkUrl, "_blank");
    }

    return (
      <a
        href={linkUrl}
        onClick={redirectionLogic}
        >
        <span pointerEvents="none">
          {convertNodeToElement (node, index, addDisclaimersToCmsLinks)}
        </span>
      </a>
    )
  }
}

export function simpleStringify (object){
  var simpleObject = {};
  for (var prop in object ){
      if (!object.hasOwnProperty(prop)){
          continue;
      }
      if (typeof(object[prop]) == 'object'){
          continue;
      }
      if (typeof(object[prop]) == 'function'){
          continue;
      }
      simpleObject[prop] = object[prop];
  }
  return JSON.stringify(simpleObject);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const transformMarkdownToReact = (htmlAst, showDisclaimer) => {
  if(isNullUndefined(htmlAst)){
    return <React.Fragment />
  }

  console.log("ast", htmlAst)

  try {
    return htmlAst?.children?.map((astChild, astIndex) => {
        if(astChild?.type === "text"){
            return (
                <p key={astIndex}>
                    {astChild?.value || ""}
                </p>
            )
        }

        if(astChild?.type === "element" && astChild?.tagName === "p"){
          return (
            <p>
              {astChild?.children?.map((pChild, pIndex) => {
                if(pChild?.type === "text"){
                  return <React.Fragment key={pIndex}>{pChild?.value}</React.Fragment>
                }else if(pChild?.type === "element" && pChild?.tagName === "a"){
                  return (
                    <a style={{cursor: "pointer"}} onClick={(e) => {
                      e.preventDefault();
                      if(isExternalButNotMailLink(pChild?.properties?.href)){
                        showDisclaimer(pChild?.properties?.href);
                      }else{
                        window.open(pChild?.properties?.href, "_blank");
                      }
                    }}>
                      {pChild?.children?.map((innerElement, innerElementIndex) => {
                        if(innerElement?.type === "text"){
                          return <React.Fragment key={innerElementIndex}>{innerElement?.value}</React.Fragment>
                        }
                      })}
                    </a>
                  )
                }else if(pChild?.type === "element" && pChild?.tagName === "u"){
                  return (
                    <u>
                      {pChild?.children?.map((pChildMappedElement, pChildMappedIndex) => {
                        if(pChildMappedElement?.type === "text"){
                          return <React.Fragment>{pChildMappedElement?.value}</React.Fragment>
                        }
                        if(pChildMappedElement?.type === "element" && pChildMappedElement?.tagName === "em"){
                          return <em>{pChildMappedElement?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</em>
                        }
                      })}
                    </u>
                  )
                }else if(pChild?.type === "element" && pChild?.tagName === "strong"){
                  return (
                    <strong>
                      {pChild?.children?.map((pChildMappedElement, pChildMappedIndex) => {
                        if(pChildMappedElement?.type === "text"){
                          return <React.Fragment>{pChildMappedElement?.value}</React.Fragment>
                        }
                        if(pChildMappedElement?.type === "element" && pChildMappedElement?.tagName === "em"){
                          return <em>{pChildMappedElement?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</em>
                        }
                      })}
                    </strong>
                  )
                }else if(pChild?.type === "element" && pChild?.tagName === "em"){
                  return (
                    <em>
                      {pChild?.children?.map((pChildMappedElement, pChildMappedIndex) => {
                        if(pChildMappedElement?.type === "text"){
                          return <React.Fragment>{pChildMappedElement?.value}</React.Fragment>
                        }
                        if(pChildMappedElement?.type === "element" && pChildMappedElement?.tagName === "strong"){
                          return <strong>{pChildMappedElement?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</strong>
                        }
                      })}
                    </em>
                  )
                }
              })}
            </p>
          )
        }

        if(astChild?.type === "element" && astChild?.tagName === "h1"){
          return (
            <h1>
              {astChild?.children?.map((hChild, hIndex) => {
                if(hChild?.type === "text"){
                  return <React.Fragment>{hChild?.value}</React.Fragment>
                }else if(hChild?.type === "element" && hChild?.tagName === "a"){
                  return (
                    <a style={{cursor: "pointer"}} onClick={(e) => {
                      e.preventDefault();
                      if(isExternalButNotMailLink(hChild?.properties?.href)){
                        showDisclaimer(hChild?.properties?.href);
                      }else{
                        window.open(hChild?.properties?.href, "_blank");
                      }
                    }}>
                      {hChild?.children?.map((innerElement, innerElementIndex) => {
                        if(innerElement?.type === "text"){
                          return <React.Fragment key={innerElementIndex}>{innerElement?.value}</React.Fragment>
                        }
                      })}
                    </a>
                  )
                }else if(hChild?.type === "element" && hChild?.tagName === "u"){
                  return (
                    <u>
                      {hChild?.children?.map((hChildItem, hChildItemIndex) => {
                        if(hChildItem?.type === "text"){
                          return <React.Fragment>{hChildItem?.value}</React.Fragment>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "strong"){
                          return <strong>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</strong>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "em"){
                        console.log("spmethm else", hChildItem?.type)
                          return <em>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                            if(item?.type === "element" && item?.tagName === "strong"){
                              return (
                                <strong>
                                  {item?.children?.map((strongChild) => {
                                    if(strongChild?.type === "text"){
                                      return <React.Fragment>{strongChild?.value}</React.Fragment>
                                    }
                                  })}
                                </strong>
                              )
                            }
                          })}</em>
                        }
                      })}
                    </u>
                  )
                }
              })}
            </h1>
          )
        }

        if(astChild?.type === "element" && astChild?.tagName === "h2"){
          return (
            <h2>
              {astChild?.children?.map((hChild, hIndex) => {
                if(hChild?.type === "text"){
                  return <React.Fragment>{hChild?.value}</React.Fragment>
                }else if(hChild?.type === "element" && hChild?.tagName === "a"){
                  return (
                    <a style={{cursor: "pointer"}} onClick={(e) => {
                      e.preventDefault();
                      if(isExternalButNotMailLink(hChild?.properties?.href)){
                        showDisclaimer(hChild?.properties?.href);
                      }else{
                        window.open(hChild?.properties?.href, "_blank");
                      }
                    }}>
                      {hChild?.children?.map((innerElement, innerElementIndex) => {
                        if(innerElement?.type === "text"){
                          return <React.Fragment key={innerElementIndex}>{innerElement?.value}</React.Fragment>
                        }
                      })}
                    </a>
                  )
                }else if(hChild?.type === "element" && hChild?.tagName === "u"){
                  return (
                    <u>
                      {hChild?.children?.map((hChildItem, hChildItemIndex) => {
                        if(hChildItem?.type === "text"){
                          return <React.Fragment>{hChildItem?.value}</React.Fragment>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "strong"){
                          return <strong>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</strong>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "em"){
                        console.log("spmethm else", hChildItem?.type)
                          return <em>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                            if(item?.type === "element" && item?.tagName === "strong"){
                              return (
                                <strong>
                                  {item?.children?.map((strongChild) => {
                                    if(strongChild?.type === "text"){
                                      return <React.Fragment>{strongChild?.value}</React.Fragment>
                                    }
                                  })}
                                </strong>
                              )
                            }
                          })}</em>
                        }
                      })}
                    </u>
                  )
                }
              })}
            </h2>
          )
        }

        if(astChild?.type === "element" && astChild?.tagName === "h3"){
          return (
            <h3>
              {astChild?.children?.map((hChild, hIndex) => {
                if(hChild?.type === "text"){
                  return <React.Fragment>{hChild?.value}</React.Fragment>
                }else if(hChild?.type === "element" && hChild?.tagName === "a"){
                  return (
                    <a style={{cursor: "pointer"}} onClick={(e) => {
                      e.preventDefault();
                      if(isExternalButNotMailLink(hChild?.properties?.href)){
                        showDisclaimer(hChild?.properties?.href);
                      }else{
                        window.open(hChild?.properties?.href, "_blank");
                      }
                    }}>
                      {hChild?.children?.map((innerElement, innerElementIndex) => {
                        if(innerElement?.type === "text"){
                          return <React.Fragment key={innerElementIndex}>{innerElement?.value}</React.Fragment>
                        }
                      })}
                    </a>
                  )
                }else if(hChild?.type === "element" && hChild?.tagName === "u"){
                  return (
                    <u>
                      {hChild?.children?.map((hChildItem, hChildItemIndex) => {
                        if(hChildItem?.type === "text"){
                          return <React.Fragment>{hChildItem?.value}</React.Fragment>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "strong"){
                          return <strong>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</strong>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "em"){
                        console.log("spmethm else", hChildItem?.type)
                          return <em>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                            if(item?.type === "element" && item?.tagName === "strong"){
                              return (
                                <strong>
                                  {item?.children?.map((strongChild) => {
                                    if(strongChild?.type === "text"){
                                      return <React.Fragment>{strongChild?.value}</React.Fragment>
                                    }
                                  })}
                                </strong>
                              )
                            }
                          })}</em>
                        }
                      })}
                    </u>
                  )
                }
              })}
            </h3>
          )
        }

        if(astChild?.type === "element" && astChild?.tagName === "h4"){
          return (
            <h4>
              {astChild?.children?.map((hChild, hIndex) => {
                if(hChild?.type === "text"){
                  return <React.Fragment>{hChild?.value}</React.Fragment>
                }else if(hChild?.type === "element" && hChild?.tagName === "a"){
                  return (
                    <a style={{cursor: "pointer"}} onClick={(e) => {
                      e.preventDefault();
                      if(isExternalButNotMailLink(hChild?.properties?.href)){
                        showDisclaimer(hChild?.properties?.href);
                      }else{
                        window.open(hChild?.properties?.href, "_blank");
                      }
                    }}>
                      {hChild?.children?.map((innerElement, innerElementIndex) => {
                        if(innerElement?.type === "text"){
                          return <React.Fragment key={innerElementIndex}>{innerElement?.value}</React.Fragment>
                        }
                      })}
                    </a>
                  )
                }else if(hChild?.type === "element" && hChild?.tagName === "u"){
                  return (
                    <u>
                      {hChild?.children?.map((hChildItem, hChildItemIndex) => {
                        if(hChildItem?.type === "text"){
                          return <React.Fragment>{hChildItem?.value}</React.Fragment>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "strong"){
                          return <strong>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</strong>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "em"){
                        console.log("spmethm else", hChildItem?.type)
                          return <em>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                            if(item?.type === "element" && item?.tagName === "strong"){
                              return (
                                <strong>
                                  {item?.children?.map((strongChild) => {
                                    if(strongChild?.type === "text"){
                                      return <React.Fragment>{strongChild?.value}</React.Fragment>
                                    }
                                  })}
                                </strong>
                              )
                            }
                          })}</em>
                        }
                      })}
                    </u>
                  )
                }
              })}
            </h4>
          )
        }

        if(astChild?.type === "element" && astChild?.tagName === "h5"){
          return (
            <h5>
              {astChild?.children?.map((hChild, hIndex) => {
                if(hChild?.type === "text"){
                  return <React.Fragment>{hChild?.value}</React.Fragment>
                }else if(hChild?.type === "element" && hChild?.tagName === "a"){
                  return (
                    <a style={{cursor: "pointer"}} onClick={(e) => {
                      e.preventDefault();
                      if(isExternalButNotMailLink(hChild?.properties?.href)){
                        showDisclaimer(hChild?.properties?.href);
                      }else{
                        window.open(hChild?.properties?.href, "_blank");
                      }
                    }}>
                      {hChild?.children?.map((innerElement, innerElementIndex) => {
                        if(innerElement?.type === "text"){
                          return <React.Fragment key={innerElementIndex}>{innerElement?.value}</React.Fragment>
                        }
                      })}
                    </a>
                  )
                }else if(hChild?.type === "element" && hChild?.tagName === "u"){
                  return (
                    <u>
                      {hChild?.children?.map((hChildItem, hChildItemIndex) => {
                        if(hChildItem?.type === "text"){
                          return <React.Fragment>{hChildItem?.value}</React.Fragment>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "strong"){
                          return <strong>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</strong>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "em"){
                        console.log("spmethm else", hChildItem?.type)
                          return <em>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                            if(item?.type === "element" && item?.tagName === "strong"){
                              return (
                                <strong>
                                  {item?.children?.map((strongChild) => {
                                    if(strongChild?.type === "text"){
                                      return <React.Fragment>{strongChild?.value}</React.Fragment>
                                    }
                                  })}
                                </strong>
                              )
                            }
                          })}</em>
                        }
                      })}
                    </u>
                  )
                }
              })}
            </h5>
          )
        }

        if(astChild?.type === "element" && astChild?.tagName === "h6"){
          return (
            <h6>
              {astChild?.children?.map((hChild, hIndex) => {
                if(hChild?.type === "text"){
                  return <React.Fragment>{hChild?.value}</React.Fragment>
                }else if(hChild?.type === "element" && hChild?.tagName === "a"){
                  return (
                    <a style={{cursor: "pointer"}} onClick={(e) => {
                      e.preventDefault();
                      if(isExternalButNotMailLink(hChild?.properties?.href)){
                        showDisclaimer(hChild?.properties?.href);
                      }else{
                        window.open(hChild?.properties?.href, "_blank");
                      }
                    }}>
                      {hChild?.children?.map((innerElement, innerElementIndex) => {
                        if(innerElement?.type === "text"){
                          return <React.Fragment key={innerElementIndex}>{innerElement?.value}</React.Fragment>
                        }
                      })}
                    </a>
                  )
                }else if(hChild?.type === "element" && hChild?.tagName === "u"){
                  return (
                    <u>
                      {hChild?.children?.map((hChildItem, hChildItemIndex) => {
                        if(hChildItem?.type === "text"){
                          return <React.Fragment>{hChildItem?.value}</React.Fragment>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "strong"){
                          return <strong>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                          })}</strong>
                        }
                        if(hChildItem?.type === "element" && hChildItem?.tagName === "em"){
                        console.log("spmethm else", hChildItem?.type)
                          return <em>{hChildItem?.children?.map((item, i) => {
                            if(item?.type === "text"){
                              return <React.Fragment>{item?.value}</React.Fragment>
                            }
                            if(item?.type === "element" && item?.tagName === "strong"){
                              return (
                                <strong>
                                  {item?.children?.map((strongChild) => {
                                    if(strongChild?.type === "text"){
                                      return <React.Fragment>{strongChild?.value}</React.Fragment>
                                    }
                                  })}
                                </strong>
                              )
                            }
                          })}</em>
                        }
                      })}
                    </u>
                  )
                }
              })}
            </h6>
          )
        }
        
        if(astChild?.type === "element" && astChild?.tagName === "ul"){
          return (
            <ul>
              {astChild?.children?.map((hChild, hIndex) => {
                if(hChild?.type === "text"){
                  return <React.Fragment>{hChild?.value}</React.Fragment>
                }
                if(hChild?.type === "element" && hChild?.tagName === "li"){
                  return (
                    <li>
                      {hChild?.children?.map((listChild, lChildIndex) => {
                        if(listChild?.type === "text"){
                          return <React.Fragment key={lChildIndex}>{listChild?.value}</React.Fragment>
                        }
                        if(listChild?.type === "element" && listChild?.tagName === "p"){
                          return (
                            <p>
                              {listChild?.children?.map((pListChild, pListIndex) => {
                                if(pListChild?.type === "text"){
                                  return <React.Fragment key={pListIndex}>{pListChild?.value}</React.Fragment>
                                }
                              })}
                            </p>
                          )
                        }
                        
                        if(listChild?.type === "element" && listChild?.tagName === "h1"){
                          return (
                            <h1>
                              {listChild?.children?.map((hlistChild, hListIndex) => {
                                if(hlistChild?.type === "text"){
                                  return <React.Fragment>{hlistChild?.value}</React.Fragment>
                                }
                              })}
                            </h1>
                          )
                        }
                        
                        if(listChild?.type === "element" && listChild?.tagName === "h2"){
                          return (
                            <h2>
                              {listChild?.children?.map((hlistChild, hListIndex) => {
                                if(hlistChild?.type === "text"){
                                  return <React.Fragment>{hlistChild?.value}</React.Fragment>
                                }
                              })}
                            </h2>
                          )
                        }
                        if(listChild?.type === "element" && listChild?.tagName === "h3"){
                          return (
                            <h3>
                              {listChild?.children?.map((hlistChild, hListIndex) => {
                                if(hlistChild?.type === "text"){
                                  return <React.Fragment>{hlistChild?.value}</React.Fragment>
                                }
                              })}
                            </h3>
                          )
                        }
                        if(listChild?.type === "element" && listChild?.tagName === "pre"){
                          return (
                            <pre className="pre_tags" style={{background: "unset", fontSize:"inherit", fontFamily: "inherit", whiteSpace:"break-spaces"}}>
                           
                              {listChild?.children?.map((preListChild, preListIndex) => {
                                if(preListChild?.type === "element" && preListChild?.tagName === "code"){
                                  return (
                                    <React.Fragment key={preListIndex}>
                                    {preListChild?.children?.map((preChild, preIndex) => {
                                      if(preChild?.type === "text"){
                                        return <React.Fragment key={preIndex}>{preChild?.value}</React.Fragment>
                                      }
                                    })}
                                  </React.Fragment>
                                  )
                                }
                              })}
                             
                            </pre>
                          )
                        }

                        console.log(listChild)
                      })}
                    </li>
                  )
                }
              })}
            </ul>
          )
        }
        if(astChild.type === "element" && astChild.tagName === "ol"){
          return (
            <ol>
              {astChild.children.map((hChild, hIndex) => {
                if(hChild.type === "text"){
                  return <React.Fragment>{hChild.value}</React.Fragment>
                }
                if(hChild.type === "element" && hChild.tagName === "li"){
                  return (
                    <li>
                      {hChild?.children?.map((listChild, lChildIndex) => {
                        if(listChild?.type === "text"){
                          return <React.Fragment key={lChildIndex}>{listChild?.value}</React.Fragment>
                        }
                        if(listChild?.type === "element" && listChild?.tagName === "p"){
                          return (
                            <p>
                              {listChild?.children?.map((pListChild, pListIndex) => {
                                if(pListChild?.type === "text"){
                                  return <React.Fragment key={pListIndex}>{pListChild?.value}</React.Fragment>
                                }
                              })}
                            </p>
                          )
                        }
                        
                        if(listChild?.type === "element" && listChild?.tagName === "h1"){
                          return (
                            <h1>
                              {listChild?.children?.map((hlistChild, hListIndex) => {
                                if(hlistChild?.type === "text"){
                                  return <React.Fragment>{hlistChild?.value}</React.Fragment>
                                }
                              })}
                            </h1>
                          )
                        }
                        
                        if(listChild?.type === "element" && listChild?.tagName === "h2"){
                          return (
                            <h2>
                              {listChild?.children?.map((hlistChild, hListIndex) => {
                                if(hlistChild?.type === "text"){
                                  return <React.Fragment>{hlistChild?.value}</React.Fragment>
                                }
                              })}
                            </h2>
                          )
                        }
                        if(listChild?.type === "element" && listChild?.tagName === "h3"){
                          return (
                            <h3>
                              {listChild?.children?.map((hlistChild, hListIndex) => {
                                if(hlistChild?.type === "text"){
                                  return <React.Fragment>{hlistChild?.value}</React.Fragment>
                                }
                              })}
                            </h3>
                          )
                        }
                        if(listChild?.type === "element" && listChild?.tagName === "pre"){
                          return (
                            <pre className="pre_tags" style={{background: "unset", fontSize:"inherit", fontFamily: "inherit", whiteSpace:"break-spaces"}}>
                           
                              {listChild?.children?.map((preListChild, preListIndex) => {
                                if(preListChild?.type === "element" && preListChild?.tagName === "code"){
                                  return (
                                    <React.Fragment key={preListIndex}>
                                    {preListChild?.children?.map((preChild, preIndex) => {
                                      if(preChild?.type === "text"){
                                        return <React.Fragment key={preIndex}>{preChild?.value}</React.Fragment>
                                      }
                                    })}
                                  </React.Fragment>
                                  )
                                }
                              })}
                             
                            </pre>
                          )
                        }
                      })}
                    </li>
                  )
                }
              })}
            </ol>
          )
        }
         

        if(astChild.type === "element" && astChild.tagName === "table"){
            return (
                <table className="table table-bordered vertical-align-middle">
                    {astChild.children.map((tableChild, tableIndex) => {
                        if(tableChild.tagName === "thead"){
                            return (
                                <thead key={tableIndex}>
                                    {tableChild.children.map((theadChild, theadIndex) => {
                                        if(theadChild.tagName === "tr"){
                                            return (
                                                <tr key={theadIndex}>
                                                  {theadChild.children.map((trChild, trIndex) => {
                                                      if(trChild.tagName === "th"){
                                                          return (
                                                              <th>
                                                                {trChild.children.map((innerElement, innerElementIndex) => {
                                                                  if(innerElement.type === "text"){
                                                                    return <React.Fragment>{innerElement.value}</React.Fragment>
                                                                  }
                                                                })}
                                                              </th>
                                                          )
                                                      }
                                                  })} 
                                                </tr>
                                            )
                                        }
                                    })}
                                </thead>
                            )
                        }else if(tableChild.tagName === "tbody"){
                            return (
                                <tbody>
                                    {tableChild.children.map((tbodyChild, tbodyIndex) => {
                                        if(tbodyChild.tagName === "tr"){
                                            return (
                                                <tr key={tbodyIndex}>
                                                    {tbodyChild.children.map((trChild, trIndex) => {
                                                        if(trChild.tagName === "td"){
                                                            return (
                                                                <td key={trIndex}>
                                                                    {trChild.children.map((elementChild, elementIndex) => {
                                                                        if(elementChild.tagName === "a"){
                                                                            return (
                                                                              <a key={elementIndex} href={elementChild.properties.href} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if(isExternalButNotMailLink(elementChild.properties.href)){
                                                                                  showDisclaimer(elementChild.properties.href);
                                                                                }else{
                                                                                  window.open(elementChild.properties.href, "_blank");
                                                                                }
                                                                              }}>
                                                                                  {elementChild.children.map((nestedElement, nestedElementIndex) => {
                                                                                    if(nestedElement.tagName === "img"){
                                                                                      return <img key={nestedElementIndex} src={nestedElement.properties.src}  />
                                                                                    }
                                                                                  })}
                                                                              </a>
                                                                            )
                                                                        }
                                                                        if(elementChild.type === "text"){
                                                                          return (
                                                                            <span key={elementIndex}>{elementChild.value}</span>
                                                                          )
                                                                        }
                                                                    })}
                                                                </td>
                                                            )
                                                        }

                                                    })}
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            )
                        }
                    })}
                </table>
            )
        } 
    })
  }catch(e){
    console.log("Can't parse markdown", e);
    return <React.Fragment />;
  }
}

export function alphabeticalSorter(a, b){
  if(a < b) return -1
  if(a > b) return 1
  return 0
}

export function replaceNewlinesWithHtmlLineBreaks(str){
    try {
      let newString = str.split("\n").join("<br />")
      return newString;
    }catch(e){
      return ""
    }
}
