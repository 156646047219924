import React from "react"
import {navigate} from "gatsby"
import {isNullUndefined} from "./index"

//URL Config
export const localAndStaging = {
    defaultBaseUrl: '',
    ukResidentsBaseUrl: '',
    nonUkResidentsBaseUrl: '',
}

export const production = {
    defaultBaseUrl: 'https://diurnal.com',
    ukResidentsBaseUrl: 'https://diurnal.co.uk',
    nonUkResidentsBaseUrl: 'https://diurnal.com'
}

export const isProduction = (() => {
    return process.env.GATSBY_ACTIVE_ENV === "production";
})()

const urls = () => {
    if (process.env.GATSBY_ACTIVE_ENV === 'production') return production;
    return localAndStaging;
}

const apiConfigs = () => {
    return {
        apiName: "DiurnalApi",
        apiEndpoint: process.env.GATSBY_ACTIVE_ENV === "production" ? "https://tijgq1yaek.execute-api.eu-west-1.amazonaws.com/dev" : "https://tijgq1yaek.execute-api.eu-west-1.amazonaws.com/dev",
        approveUserPath: "/approveUser",
        isUserApprovedPath: "/isUserApproved",
        getUserPath: "/getUser"
    }
}

export const config = {
    ...urls(),
    ...apiConfigs(),
    logoUrl: `https://diurnal-assets180018-dev.s3-eu-west-1.amazonaws.com/images/logo.png`,
};
//End Urls config

export function getDefaultMainLandingDisclaimerPopupConfigsInvestors(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is intended for investors and media professionals and is not for the general public. By clicking continue, you acknowledge that you are an investor/potential investor or media professional",
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess? {} : navigate(path),
        closeCallback: () => isDirectAccess? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}

export function getUsaOnlyInvestorDisclaimerPopupConfigsInvestors(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is intended for US investors and is not for the general public. By clicking continue, you acknowledge that you are a US investor/potential investor",
        Message: () => <p>This site is intended for US investors and is not for the general public. By clicking continue, you acknowledge that you are a US investor/potential investor</p>,
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getDefaultMainLandingDisclaimerPopupConfigsUKResidents(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is intended for UK based Healthcare Professionals and Patients. By clicking continue, you are acknowledging that you are a UK based resident",
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess ? {} : navigate(path),
        closeCallback: () => isDirectAccess ? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}

export function getDefaultMainLandingDisclaimerPopupConfigsNonUKResidents(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is intended for Non-UK based Healthcare Professionals and Patients. By clicking continue, you are acknowledging that you are not a resident of the UK",
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess ? {} : navigate(path),
        closeCallback: () => isDirectAccess ? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}

export function getUkResidentsHCPLandingDisclaimerPopupConfig(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is for UK Healthcare Professionals. By clicking you are acknowledging that you are a UK Healthcare Professional",
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess ? {} : navigate(path),
        closeCallback: () => isDirectAccess ? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}

export function getUkResidentsNonHCPLandingDisclaimerPopupConfig(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is for UK Non Healthcare Professionals. By clicking you are acknowledging that you are a UK Non Healthcare Professional",
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess ? {} : navigate(path),
        closeCallback: () => isDirectAccess ? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}

export function getNonUkResidentsHCPLandingDisclaimerPopupConfig(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is for Non-UK Healthcare Professionals. By clicking you are acknowledging that you are a Non-UK Healthcare Professional",
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess ? {} : navigate(path),
        closeCallback: () => isDirectAccess ? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}

export function getNonUkResidentsNonHCPLandingDisclaimerPopupConfig(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This site is for Non-UK Non Healthcare Professionals. By clicking you are acknowledging that you are a Non-UK Non Healthcare Professional",
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess ? {} : navigate(path),
        closeCallback: () => isDirectAccess ? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}

export function getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback){
    let currentUrl = window.location.origin;
    const Message = () => {
        return (
            <span>
                You are leaving {currentUrl}. Links to all outside websites are provided as a resource to our visitors. Outside websites are neither owned or controlled by Diurnal Ltd. Diurnal accepts no responsibility for the content of other websites.
            </span>
        )
    }
    return { 
        show: true,
        title: null,
        Message: Message,
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getDefaultUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback){
    let currentUrl = window.location.origin;
    return { 
        show: true,
        title: null,
        message: `You are leaving ${currentUrl}. Links to all outside websites are provided as a resource to our visitors. Outside websites are neither owned or controlled by Diurnal Ltd. Diurnal accepts no responsibility for the content of other websites.`,
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getDefaultUkResidentsNonHcpDisclaimerPopupConfigs(okCallback, closeCallback){
    let currentUrl = window.location.origin;
    return { 
        show: true,
        title: null,
        message: `You are leaving ${currentUrl}. Links to all outside websites are provided as a resource to our visitors. Outside websites are neither owned or controlled by Diurnal Ltd. Diurnal accepts no responsibility for the content of other websites.`,
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getDefaultNonUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback){
    let currentUrl = window.location.origin;
    return { 
        show: true,
        title: null,
        message: `You are leaving ${currentUrl}. Links to all outside websites are provided as a resource to our visitors. Outside websites are neither owned or controlled by Diurnal Ltd. Diurnal accepts no responsibility for the content of other websites.`,
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getDefaultNonUkResidentsNonHcpDisclaimerPopupConfigs(okCallback, closeCallback){
    let currentUrl = window.location.origin;
    return { 
        show: true,
        title: null,
        message: `You are leaving ${currentUrl}. Links to all outside websites are provided as a resource to our visitors. Outside websites are neither owned or controlled by Diurnal Ltd. Diurnal accepts no responsibility for the content of other websites.`,
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback,
    }
}

export function getNonUkResidentsNonHcpGermanPatientsDisclaimerPopupConfigs(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "Dieser Abschnitt ist für deutsche Patienten gedacht. Wenn Sie auf „Weiter“ klicken, bestätigen Sie, dass Sie ein Patient / Pfleger sind und dass Ihnen Alkindi® (Hydrocortison-Granulat zur Entnahme aus Kapseln) von einem deutschen Arzt verschrieben wurde.",
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isDirectAccess ? () => { } : isNullUndefined(okCallback) ? () => { } : okCallback,
        closeCallback: isDirectAccess ? () => navigate('/') : isNullUndefined(closeCallback) ? () => { } : closeCallback,
        isDirectAccess: isDirectAccess
    }
}

export function getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigs(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This section is intended for UK patients. By clicking continue, you are confirming that you are a patient, or the caregiver of a patient prescribed Alkindi® (hydrocortisone granules in capsules for opening) by a UK healthcare professional",
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isDirectAccess? () => {} : isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback:isDirectAccess? () => navigate('/') : isNullUndefined(closeCallback) ? () => {} : closeCallback,
        isDirectAccess: isDirectAccess
    }
}

export function getUkResidentsNonHcpUKPatientsDisclaimerPopupConfigsEfmody(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "This section is intended for UK patients. By clicking continue, you are confirming that you are a patient, or the caregiver of a patient prescribed Efmody® (hydrocortisone modified-release hard capsules) by a UK healthcare professional",
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isDirectAccess? () => {} : isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback:isDirectAccess? () => navigate('/') : isNullUndefined(closeCallback) ? () => {} : closeCallback,
        isDirectAccess: isDirectAccess
    }
}

export function getInvestorApplyOnlinePopupConfigs(okCallback, closeCallback, textConfig){
    return {
        show: true,
        title: null,
        jobPosition: textConfig.jobTitle,
        jobType: textConfig.jobType,
        country: textConfig.country,
        location: textConfig.location,
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigs(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "Dieser Abschnitt ist für deutsche Patienten gedacht. Wenn Sie auf „Weiter“ klicken, bestätigen Sie, dass Sie ein Patient / Pfleger sind und dass Ihnen Alkindi® (Hydrocortison-Granulat zur Entnahme aus Kapseln) von einem deutschen Arzt verschrieben wurde.",
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isDirectAccess ? () => { } : isNullUndefined(okCallback) ? () => { } : okCallback,
        closeCallback: isDirectAccess ? () => navigate('/') : isNullUndefined(closeCallback) ? () => { } : closeCallback,
        isDirectAccess: isDirectAccess
    }
}

export function getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "Dieser Abschnitt ist für deutsche Patienten gedacht. Wenn Sie auf „Weiter“ klicken, bestätigen Sie, dass Sie ein Patient / Pfleger sind und dass Ihnen Efmody® (Hydrocortison-Hartkapseln mit veränderter Wirkstofffreisetzung) von einem deutschen Arzt verschrieben wurde.",
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isDirectAccess ? () => { } : isNullUndefined(okCallback) ? () => { } : okCallback,
        closeCallback: isDirectAccess ? () => navigate('/') : isNullUndefined(closeCallback) ? () => { } : closeCallback,
        isDirectAccess: isDirectAccess
    }
}

export function getNonUkResidentsNonHcpGermanInfoDisclaimerPopupConfigsEfmody2(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        message: "Dieser Abschnitt ist für deutsche Patienten gedacht. Wenn Sie auf „Weiter“ klicken, bestätigen Sie, dass Sie ein Patient / Pfleger sind und dass Ihnen Efmody® (Hydrocortison-Hartkapseln mit veränderter Wirkstofffreisetzung) von einem deutschen Arzt verschrieben wurde.",
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isDirectAccess ? () => { } : isNullUndefined(okCallback) ? () => { } : okCallback,
        closeCallback: isDirectAccess ? () => navigate('/') : isNullUndefined(closeCallback) ? () => { } : closeCallback,
        isDirectAccess: isDirectAccess
    }
}

export function getNonUkResidentsNonHcpCzechInfoDisclaimerPopupConfigs(okCallback, closeCallback){
    let currentUrl = window.location.origin;
    return { 
        show: true,
        title: null,
        message: `You are leaving ${currentUrl}. Links to all outside websites are provided as a resource to our visitors. Outside websites are neither owned or controlled by Diurnal Ltd. Diurnal accepts no responsibility for the content of other websites.`,
        okLabel: "Continue",
        closeLabel: "Cancel",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getNonUkClinicalTrialsDisclaimerPopupConfigs(okCallback, closeCallback, isDirectAccess){
    return { 
        show: true,
        title: null,
        Message: () => (
            <p>
                This site contains information about clinical trials sponsored by Diurnal. The information on this site may relate to clinical trials concerning study drugs or therapeutic uses that have not been approved by drug regulatory agencies.
                <br /><br />
                The information contained in this site is not intended to promote in any way, any use, either approved, or unapproved, of a Diurnal product or of any other product. Furthermore, it is not intended to replace the advice of a healthcare professional and should not be construed as providing advice or making a recommendation.
            </p>
        ),
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: isNullUndefined(okCallback) ? () => {} : okCallback,
        closeCallback: isNullUndefined(closeCallback) ? () => {} : closeCallback
    }
}

export function getNonUkClinicalTrialsDisclaimerDirectAccessPopupConfigs(path, isDirectAccess){
    return { 
        show: true,
        title: null,
        Message: () => (
            <p>
                This site contains information about clinical trials sponsored by Diurnal. The information on this site may relate to clinical trials concerning study drugs or therapeutic uses that have not been approved by drug regulatory agencies.
                <br /><br />
                The information contained in this site is not intended to promote in any way, any use, either approved, or unapproved, of a Diurnal product or of any other product. Furthermore, it is not intended to replace the advice of a healthcare professional and should not be construed as providing advice or making a recommendation.
            </p>
        ),
        okLabel: "Continue",
        closeLabel: "Close",
        okCallback: () => isDirectAccess ? {} : navigate(path),
        closeCallback: () => isDirectAccess ? navigate('/') : {},
        isDirectAccess: isDirectAccess
    }
}